/* Header.css */

.banner {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #1c1c1c;
  color: #ffffff;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  max-width: 100%;
}

.left-section {
  display: flex;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
}

.logo {
  height: 40px;
  margin-left: 10px; /* Add some space between the menu icon and the logo */
}

.login-info {
  display: flex;
  align-items: center;
}

.wallet-name {
  margin-right: 10px;
}

.login-button {
  background-color: #e1d11a; /* Primary yellow color */
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.login-button:hover {
  background-color: #b5a313;
}

.logout-button {
  background-color: #e74c3c;
  border: none;
  color: white;
  padding: 5px 10px; /* Add padding here */
  cursor: pointer;
  border-radius: 3px;
}

.logout-button:hover {
  background-color: #c0392b; /* Optional: Add a hover effect for the logout button */
}


.loading-bar {
  height: 4px;
  width: 100%;
  background-color: #e1d11a;
}

.loading-bar.pulsing {
  background: linear-gradient(90deg, transparent, #e1d11a, transparent);
  background-size: 200% 100%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}
