body {
  margin: 0;
  padding: 0;
  background-color: #111; /* Dark background color */
  color: #fff; /* Text color */
  font-family: Arial, sans-serif;
}

.content {
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center; /* Center the content */
  width: 100vw; /* Use the full width of the viewport */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.content.shifted {
  transform: translateX(250px); /* Shift content by the width of the menu */
  width: calc(100vw - 250px); /* Adjust width when the menu is expanded */
}

.sales-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  width: 100%; /* Use the full width of the container */
  margin: 0; /* Remove any default margin */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.content h2 {
  position: relative; /* Ensure the heading is above the content */
  z-index: 1; /* Ensure it is above other elements */
}
