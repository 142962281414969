/* Menu.css */

.menu-content {
    position: fixed;
    top: 64px; /* Adjust this value to match the height of your header */
    left: 0;
    width: 250px;
    height: calc(100% - 64px); /* Adjust this value to match the height of your header */
    background-color: #1c1c1c;
    color: #ffffff;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 999; /* Ensure the menu is above other content */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.menu-content.open {
    transform: translateX(0);
}

.menu-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Ensure the menu starts from the top */
    flex-grow: 1;
}

.menu-content li {
    padding: 15px 20px;
    /* Remove border-bottom to remove the small grey borders */
}

.menu-content a {
  color: #ffffff;
  text-decoration: none;
  display: block;
}

.menu-content a:hover {
  font-weight: bold;
}

/* To make the text bold on selection */
.menu-content a::selection {
  font-weight: bold;
}
