/* Marketplace.css */

/* Resetting margin and padding for the body and html */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrollbar */
}

/* Center the content heading */
.content h2 {
    text-align: center;
    margin: 20px 0; /* Add some margin for spacing */
}

/* Default state when the menu is not expanded */
.sales-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    width: calc(100% - 40px); /* Full width minus padding for left and right */
    padding: 20px 20px 0 20px; /* Padding top, left, and right */
    box-sizing: border-box; /* Include padding in the width calculation */
    margin: 0 auto; /* Center the grid within its container */
}

/* When the menu is expanded */
.menu-expanded .sales-grid {
    width: calc(100% - 250px - 40px); /* Full width minus menu width and padding for left and right */
    padding: 20px 20px 0 20px; /* Padding top, left, and right */
    box-sizing: border-box; /* Include padding in the width calculation */
    margin: 0 auto; /* Center the grid within its container */
}

/* CSS for individual sale boxes */
.sale-box {
    position: relative;
    border-radius: 10px;
    padding: 30px 5px 20px 5px; /* Adjusted padding for centralizing images */
    background-color: #333;
    font-family: 'Fjalla One', sans-serif;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 300px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in-out;
}

.image-container.fade {
    opacity: 0;
}

.sale-box img {
    max-width: 150px;
    max-height: 170px;
    min-height: 170px;
    object-fit: contain;
    margin: 0 auto;
}

.sale-info-container {
    padding: 10px;
    box-sizing: border-box;
    background-color: #333;
    color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sale-info-bold {
    margin: 0;
    padding: 5px 0;
    font-family: 'Fjalla One', sans-serif;
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;
}

.sale-info {
    margin: 0;
    font-family: 'Fjalla One', sans-serif;
    font-size: 0.8em;
    padding: 5px;
    font-weight: normal;
    text-align: center;
}

/* Loader CSS */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.pulsing-bar {
    height: 4px;
    background: linear-gradient(90deg, transparent, yellow, transparent);
    background-size: 200% 100%;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}

/* Navigation buttons for bundle listings */
.image-navigation {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.image-navigation button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
    transition: background 0.3s, opacity 0.3s;
}

.image-navigation button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.image-navigation button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.sale-img {
    transition: transform 0.3s ease-in-out;
}

/* Mint number styling */
.mint-number {
    position: absolute;
    top: 5px;
    background-color: #111;
    color: white;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 0.8em;
    text-align: center;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}
